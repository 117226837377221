import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { getNotificationsService } from "../../services/userServices";
import { decodeJWT } from "../../utils";

const NotificationComponent = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const decodedToken = decodeJWT(token);

  const [notifications, setNotifications] = useState([]);
  const [shownNotifications, setShownNotifications] = useState(new Map());

  // Handle Blocked Pages
  const blockedPages = ["/", "/Sign-Up", "/Forget-Password"];
  const isBlockedPages = blockedPages.includes(location.pathname);

  // Handle Get Notifications
  const handleGetNotification = async () => {
    try {
      const { status, data } = await getNotificationsService();
      if (status === 200) {
        setNotifications(data);
      }
    } catch ({ response }) {
      if (response?.status) {
        toast.error(response.data.message);
      }
    }
  };

  const handleInitial = async () => {
    if (!isBlockedPages) {
      await handleGetNotification();
    }
  };

  // useEffect for fetching data every 2 seconds
  useEffect(() => {
    const fetchData = async () => {
      await handleInitial();
    };
    fetchData();

    const interval = setInterval(handleInitial, 2000);
    return () => clearInterval(interval);
  }, [location.pathname]);

  // UseEffect For Show Toast Based On Order Type
  useEffect(() => {
    if (isBlockedPages) return;

    notifications.forEach((notification) => {
      const { id, transaction_id, tType } = notification;
      const key = `${id}-${tType}`;

      if (shownNotifications.has(key)) return;

      if (tType === "E") {
        toast(
          `${
            decodedToken?.usertype === "CUSTOMER"
              ? `سفارش شما ثبت شد - شماره سفارش: ${transaction_id}`
              : `سفارش جدید ثبت شد - شماره سفارش: ${transaction_id}`
          }`,
          {
            id: `toast-${key}`,
            duration: 8000,
          }
        );
      }

      if (tType === "T" && decodedToken?.usertype === "CUSTOMER") {
        toast.success(`سفارش شما تأیید شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      if (tType === "L" && decodedToken?.usertype === "CUSTOMER") {
        toast.error(`سفارش شما لغو شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      setShownNotifications((prev) => new Map(prev).set(key, true));
    });
  }, [notifications, shownNotifications]);

  return null;
};

export default NotificationComponent;
